import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Bootstrapper } from '@common/core/bootstrapper.service';
import { Settings } from '@common/core/config/settings.service';
import { RecaptchaService } from '@common/core/services/recaptcha.service';
import { ThemeService } from '@common/core/theme.service';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { User } from '@common/core/types/models/User';
import { MenuItem } from '@common/core/ui/custom-menu/menu-item';
import { Toast } from '@common/core/ui/toast.service';
import { slugifyString } from '@common/core/utils/slugify-string';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { CurrentUser } from '../current-user';
import { SocialAuthService } from '../social-auth.service';

@Component({
  selector: 'login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginRegisterComponent implements OnInit {

  public loading$ = new BehaviorSubject<boolean>(false);
    public form = this.fb.group({
        email: [''],
        password: [''],
        remember: [true],
    });

    public errResponse$ = new BehaviorSubject<Partial<User>>({});
    status: boolean = false;


    //Register
    public registerPolicies: Partial<MenuItem>[] = [];
    public formSignUp = this.fb.group({
        email: [''],
        password: [''],
        password_confirmation: [''],
        purchase_code: [''],
    });
    public errors$ = new BehaviorSubject<{
        email?: string,
        password?: string,
        general?: string,
        purchase_code?: string
    }>({});





  constructor(
    public auth: AuthService,
    public socialAuth: SocialAuthService,
    public settings: Settings,
    public route: ActivatedRoute,
    private router: Router,
    private user: CurrentUser,
    private bootstrapper: Bootstrapper,
    private fb: FormBuilder,
    private toast: Toast,
    private recaptcha: RecaptchaService,
    private theme: ThemeService,

    ) {
        this.hydrateModel();
    }

    ngOnInit() {
      this.auth.forcedEmail$
          .pipe(filter(email => !!email))
          .subscribe(email => {
              this.form.get('email').setValue(email);
              this.form.get('email').disable();
      });

      //Register
      this.registerPolicies = this.settings.getJson('register_policies', []);
        this.registerPolicies.forEach(policy => {
            policy.id = slugifyString(policy.label, '_');
            this.formSignUp.addControl(policy.id, new FormControl(false));
        });
        if (this.recaptcha.enabledFor('registration')) {
            this.recaptcha.load();
        }
        this.auth.forcedEmail$
            .pipe(filter(email => !!email))
            .subscribe(email => {
                this.formSignUp.get('email').setValue(email);
                this.formSignUp.get('email').disable();
        });
        //Register End
    }


    public async register() {
      this.loading$.next(true);
      if (this.recaptcha.enabledFor('registration') && ! await this.recaptcha.verify('registration')) {
          this.loading$.next(false);
          return this.toast.open('Could not verify you are human.');
      }

      this.auth.register(this.formSignUp.getRawValue())
          .subscribe(response => {
              if (response.status === 'needs_email_verification') {
                  this.router.navigate(['/login']).then(() => {
                      this.loading$.next(false);
                      this.toast.open(response.message, {duration: 12000});
                  });
              } else {
                  this.bootstrapper.bootstrap(response.bootstrapData);
                  this.router.navigate(['/choose-favorites']).then(() => {
                      this.loading$.next(false);
                      this.toast.open('Registered successfully.');
                  });
              }
          }, (errResponse: BackendErrorResponse) => {
              this.errors$.next(errResponse.errors);
              this.loading$.next(false);
          });
  }






  public logoUrl() {
    return this.settings.get(`branding.logo_${this.theme.isDarkMode() ? 'light' : 'dark'}`);
    }





  public login() {
      this.loading$.next(true);
      this.auth.login(this.form.getRawValue())
          .subscribe(response => {
              
              this.bootstrapper.bootstrap(response.data);

              console.log(this.bootstrapper.data.user.last_login);
              console.log(this.auth.getRedirectUri());

            

              this.router.navigate([this.auth.getRedirectUri()]).then(() => {
                  this.loading$.next(false);
              });

          }, (errResponse: BackendErrorResponse) => this.handleLoginError(errResponse));
  }

  private hydrateModel() {
      if ( ! this.settings.get('common.site.demo')) return;

      if (this.settings.get('vebto.demo.email')) {
          this.form.patchValue({
              email: this.settings.get('vebto.demo.email'),
              password: this.settings.get('vebto.demo.password'),
          });
      } else {
          // random number between 0 and 100, padded to 3 digits
          let number = '' + Math.floor(Math.random() * 100);
          number = ('0000' + number).substr(-3, 3);

          this.form.patchValue({
              email: 'admin@demo' + number + '.com',
              password: 'admin'
          });
      }
  }

  private handleLoginError(response: BackendErrorResponse) {
      
      this.loading$.next(false);
      if (response.errors?.email === 'validation.email_confirmed') {
          this.toast.open('Please confirm your email address.', {action: 'Resend Email', duration: 6000})
              .onAction()
              .subscribe(() => {
                  this.loading$.next(true);
                  this.auth.resendEmailConfirmation(this.form.value.email)
                      .pipe(finalize(() => this.loading$.next(false)))
                      .subscribe(() => {
                          this.toast.open('Confirmation email sent.');
                      });
              });
      } else {
          this.errResponse$.next(response.errors);
      }
  }

  onClick(type) {
    console.log(type);

    if (type == 'in') {
      this.status = false;
    }
    else{
      this.status = true;
    }
    
  }

}
