<div class="social-login-row envato-row" *ngIf="settings.get('social.envato.enable')">
  <div class="icon-container envato"><mat-icon svgIcon="envato-custom"></mat-icon></div>
  <div class="name" trans>Envato Account</div>
  <ng-container *ngIf="getSocialAccountUsername('envato') as username; else envatoBlock">
    <span class="social-account-username">{{ username }}</span>
    <button
      mat-raised-button
      color="accent"
      class="button primary disable-button"
      (click)="disconnectSocialAccount('envato')"
      trans
    >
      Disable
    </button>
  </ng-container>
  <ng-template #envatoBlock
    ><button
      mat-raised-button
      color="accent"
      class="button primary enable-button"
      (click)="connectSocialAccount('envato')"
      trans
    >
      Enable
    </button></ng-template
  >
</div>
<div class="social-login-row google-row" *ngIf="settings.get('social.google.enable')">
  <div class="icon-container google"><mat-icon svgIcon="google"></mat-icon></div>
  <div class="name" trans>Google+ Account</div>
  <ng-container *ngIf="getSocialAccountUsername('google') as username; else googleBlock">
    <span class="social-account-username">{{ username }}</span>
    <button
      mat-raised-button
      color="accent"
      class="button primary disable-button"
      (click)="disconnectSocialAccount('google')"
      trans
    >
      Disable
    </button>
  </ng-container>
  <ng-template #googleBlock
    ><button
      mat-raised-button
      color="accent"
      class="button primary enable-button"
      (click)="connectSocialAccount('google')"
      trans
    >
      Enable
    </button></ng-template
  >
</div>
<div class="social-login-row facebook-row" *ngIf="settings.get('social.facebook.enable')">
  <div class="icon-container facebook"><mat-icon svgIcon="facebook"></mat-icon></div>
  <div class="name" trans>Facebook Account</div>
  <ng-container *ngIf="getSocialAccountUsername('facebook') as username; else facebookBlock">
    <span class="social-account-username">{{ username }}</span>
    <button
      mat-raised-button
      color="accent"
      class="button primary disable-button"
      (click)="disconnectSocialAccount('facebook')"
      trans
    >
      Disable
    </button>
  </ng-container>
  <ng-template #facebookBlock
    ><button
      mat-raised-button
      color="accent"
      class="button primary enable-button"
      (click)="connectSocialAccount('facebook')"
      trans
    >
      Enable
    </button></ng-template
  >
</div>
<div class="social-login-row twitter-row last" *ngIf="settings.get('social.twitter.enable')">
  <div class="icon-container twitter"><mat-icon svgIcon="twitter"></mat-icon></div>
  <div class="name" trans>Twitter Account</div>
  <ng-container *ngIf="getSocialAccountUsername('twitter') as username; else twitterBlock">
    <span class="social-account-username">{{ username }}</span>
    <button
      mat-raised-button
      color="accent"
      class="button primary disable-button"
      (click)="disconnectSocialAccount('twitter')"
      trans
    >
      Disable
    </button>
  </ng-container>
  <ng-template #twitterBlock
    ><button
      mat-raised-button
      color="accent"
      class="button primary enable-button"
      (click)="connectSocialAccount('twitter')"
      trans
    >
      Enable
    </button></ng-template
  >
</div>
