<div *ngIf="isDataAvailable">
<div class="auth-page-content">
    <a class="logo" [routerLink]="['/']"><img [src]="logoUrl()" alt="logo"></a>

  
    <div class="fav-panel">



        <div class="head-img-container">
        <ngx-hm-carousel
            [(ngModel)]="index"
            [aniTime]="5000"
            [disable-drag]="true"
            [infinite]="true"
            [autoplay]="autoplay"
            [autoplay-speed]="speed"
            class="carousel c-accent"
            >
            <section ngx-hm-carousel-container class="content">
                <article
                class="item cursor-pointer"
                ngx-hm-carousel-item
                *ngFor="let avatar of avatars; let i = index"
                >
                <div
                    class="img"
                    [style.backgroundImage]="'url(' + avatar.url + ')'"
                ></div>
                </article>
            </section>

            

       



            </ngx-hm-carousel>






        

            <!-- <img class="head-img" id="img" [@fade]="fadeInStart" (@fade.done)="onDone()" [src]="imgUrl"> -->

  



            <div class="after">
                <div class="header-container ng-star-inserted">
                    <h1>Choose Your Favorites</h1>
                </div>
            </div>
        </div>


        <!-- <button type="button" (click)="change()" >Change</button> -->



        <div class="fav-panel-inner">

       





        <!-- Artists -->
        <br>
        <div class="sub-channel ng-star-inserted">
            <h2>Artists</h2>
        </div>

        <div class="grid-two imageandtext media-grid-content media-grid">

            <div class="imageandtext image_grid media-grid-item ng-star-inserted" *ngFor="let item of data.artists">
                <div class="img-containr artist-img-container">
                    <label class="label">
                    <img src="{{item.image_small}}" class="img-thumbnail artist-img">
                        <input type="checkbox" name="artists" value="{{item.id}}" (click)="checkBoxChange(2, $event, item)">
                    <span class="caption artist-caption">
                    </span>
                    </label>
                </div>

                <div class="footer">
                    <div class="track-name primary-text">
                        {{item.name}}
                    </div>
                  </div>

              </div>

        </div>



        <div class="btn-container">
            <a class="btn" type="button" (click)="sendData()">Save and Continue</a>
        </div>





        <h1 style="display: none;">{{data.artists[0]}}</h1>

        </div>

    </div>


 






    <div class="custom-menu-container">
        <a routerLink="/" class="copyright">© {{settings.get('branding.site_name')}}</a>
        <custom-menu position="auth-page-footer" [horizontal]="true"></custom-menu>
    </div>

</div>


</div>


