import {RouterModule, Routes} from '@angular/router';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {GuestGuard} from '../guards/guest-guard.service';
import {RegisterComponent} from './register/register.component';
import {DisableRouteGuard} from '../guards/disable-route-guard.service';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ChooseFavoritesComponent} from './choose-favorites/choose-favorites.component';
import { AuthGuard } from '@common/guards/auth-guard.service';
import { LoginRegisterComponent } from './login-register/login-register.component';

const routes: Routes = [
    {path: 'login', component: LoginRegisterComponent, canActivate: [GuestGuard]},
    {path: 'register', component: LoginRegisterComponent, canActivate: [GuestGuard, DisableRouteGuard]},

    {path: 'login2', component: LoginRegisterComponent, canActivate: [GuestGuard]},

    {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [GuestGuard]},
    {path: 'password/reset/:token', component: ResetPasswordComponent, canActivate: [GuestGuard]},

    {path: 'workspace/join/register', component: RegisterComponent, data: {message: 'To join your team on :siteName, create an account'}},
    {path: 'workspace/join/login', component: LoginComponent, data: {message: 'To join your team on :siteName, login to your account'}},



    {path: 'choose-favorites', component: ChooseFavoritesComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard] , data: {message: 'Choose Your Favorites items'}},    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
