import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import {Settings} from '@common/core/config/settings.service';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import { ThemeService } from '@common/core/theme.service';
import { Toast } from '@common/core/ui/toast.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CurrentUser } from '../current-user';
import 'hammerjs';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';



const fade = trigger('fade', [
  state('false', style({ transform: "translateX(0%)" })),
  state('true', style({ transform: "translateX(-100%)" })),
  transition('false => true', animate('200ms')),
  transition('true => false', animate('0s')),
]);




@Component({
  selector: 'choose-favorites',
  templateUrl: './choose-favorites.component.html',
  styleUrls: ['./choose-favorites.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [fade]
})


export class ChooseFavoritesComponent implements OnInit{

  index = 0;
  autoplay = true;
  infinite = true;
  direction = 'right';
  speed = 5000;

  avatars = '012345678'.split('').map((x, i) => {
    const num = i;
    return {
      url: `assets/images/choose-fav-page/${num}.jpg`,
      title: `${num}`
    };
  });



  str = [];
  public data: any = []
  public src: string;
  isDataAvailable:boolean = false;

  public curr = 1;
  public imgUrl = "assets/images/choose-fav-page/1.jpg";
  public fadeInStart = false;

  tracks = [];
  artists = [];


  public loading$ = new BehaviorSubject<boolean>(false);


  constructor(
    private http: HttpClient,
    public settings: Settings,
    private theme: ThemeService,
    private toast: Toast,
    private httpApp: AppHttpClient,
    protected currentUser: CurrentUser,
    private router: Router,
  ) {}


 
  public logoUrl() {
    return this.settings.get(`branding.logo_${this.theme.isDarkMode() ? 'light' : 'dark'}`);
  }


  getData(){
    const url ='secure/get-fav-items'
    this.http.get(url).subscribe((res)=>{
      this.data = res
 
    })
  }
 
  ngOnInit() {
    this.getData()
    this.loading$.next(false);
    this.isDataAvailable = true;
  }


  intervalId = setInterval(() => this.change(), 6000);


  change(){
    this.curr++;

    this.imgUrl = "assets/images/choose-fav-page/"+this.curr+".jpg";

    this.fadeInStart = true;

    if (this.curr == 9) {
      this.curr = 0;
    }

  }

  onDone() {
    this.fadeInStart = false;
  }


  async checkBoxChange(type, event, item){
    var value = event.target.value;
    var checked = event.target.checked;

    if (type == 1) {
        if (checked == true) {
          this.tracks.push(value);
        }
        else{
          var index = this.tracks.indexOf(value);
          if (index > -1) {
            this.tracks.splice(index, 1);
          }
        }

    }


    if (type == 2) {

      if (checked == true) {
        this.artists.push(value);

        var finalSimilar = []

        //Get Similar artist and view to user
        const url ='secure/get-related/' + value
        var indx = this.data.artists.indexOf(item);
        var res = await this.http.get<any>(url).toPromise();



        res.similars.forEach(element => {
          var doesExist = false;

          this.data.artists.forEach(art => {
            if (element.id == art.id) {
              console.log("Already there.");
              doesExist = true;
              res.similars.splice(res.similars.indexOf(element), 1);
            }
          });


          if (element.image_small == "https://streamwave.app/client/assets/images/default/artist_small.jpg") {
            doesExist = true;
          }

          if (!doesExist) {
            finalSimilar.push(element)
          }



        });


        if (finalSimilar.length != 0) {
          this.toast.open('Got ' + finalSimilar.length + ' more artists you may like.');
        }
        
        this.data.artists.splice(indx+1, 0, ...finalSimilar);




      }


      else{
        var index = this.artists.indexOf(value);
          if (index > -1) {
            this.artists.splice(index, 1);
          }
      }

    }


    
  }


  sendData(){
    var selected  = this.tracks.length + this.artists.length;

    if (selected < 5) {
        this.toast.open('Please select atleast 5 artists.');
        return
    }

   
 

    var data1 = [this.tracks, this.artists]

    var user = this.currentUser.get('id');
      this.loading$.next(true);

    this.httpApp.post("api/get-fav-items", {'data': data1, 'user': user} ).subscribe((res)=>{
      this.router.navigate(['/channel/recommended']).then(() => {
        
        this.toast.open('Saved successfully.');
      });
 
    })
    
    
   

  }

 
  

}
