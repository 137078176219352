
<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
<link rel="stylesheet" href="/assets/images/login-register/style.css">
 
<a class="logo" [routerLink]="['/']"><img [src]="logoUrl()" alt="logo"></a>

<div class="container" id="container" [ngClass]="status ? 'right-panel-active' : ''">

	<div class="form-container sign-up-container">
		<form (ngSubmit)="register()" *ngIf="errors$ | async as errors" [formGroup]="formSignUp" ngNativeValidate>
			<h1>Create Free Account</h1>
			<div class="social-container">
				<span>Sign up using social networks</span>
				<a href="#" class="social facebook"><i class="fab fa-facebook-f"></i></a>
				<a href="#" class="social google"><i class="fab fa-google-plus-g"></i></a>
				<a href="#" class="social linkdin"><i class="fab fa-linkedin-in"></i></a>
			</div>
			
			
			
			<div class="login-form">
				<div class="form-title-wrapper">
					<div class="form-title">OR</div>
				</div>
                
                <div class="errors">
                    <p class="error" *ngIf="errors.email">
                        <mat-icon svgIcon="warning"></mat-icon>
                        <span>{{errors.email}}</span>
                    </p>
                    <p class="error" *ngIf="errors.password">
                        <mat-icon svgIcon="warning"></mat-icon>
                        <span>{{errors.password}}</span>
                    </p>
                    <p class="error" *ngIf="errors.purchase_code">
                        <mat-icon svgIcon="warning"></mat-icon>
                        <span>{{errors.purchase_code}}</span>
                    </p>
                </div>
			
			
				<input type="text" formControlName="email" placeholder="Email" />
				<input type="password" formControlName="password" placeholder="Password" />
				<input type="password" formControlName="password_confirmation" id="register_password_confirmation" placeholder="Confirm Password" />

                <div class="input-container" *ngIf="settings.get('envato.enable') && settings.get('envato.require_purchase_code')">
                    <input type="text" formControlName="purchase_code" id="purchase_code" placeholder="Envato Purchase Code" required>
                    
                </div> 

                <div class="policies" *ngIf="registerPolicies.length">
                    <mat-checkbox name="remember-me" class="policy-checkbox" *ngFor="let policy of registerPolicies" [formControlName]="policy.id" color="accent" required trans>
                        <span trans>I accept the</span>&ngsp;
                        <a [href]="policy.type === 'link' ? policy.action : (settings.getBaseUrl() + '/' + policy.action)" *ngIf="policy.action" target="_blank">
                            <strong>{{policy.label | trans}}</strong>
                        </a>
                        <strong *ngIf=!policy.action>{{policy.label | trans}}</strong>
                    </mat-checkbox>
                </div>

				<button type="submit" [disabled]="loading$ | async" trans>Sign Up</button>
			</div>
			
			
		</form>
	</div>
	
	
	<div class="form-container sign-in-container">
        <form (ngSubmit)="login()" [formGroup]="form" *ngIf="errResponse$ | async as errors" ngNativeValidate>

			<h1>Login to Your Account</h1>
			<div class="social-container">
				<span>Login using social networks</span>
				<a href="#" class="social facebook"><i class="fab fa-facebook-f"></i></a>
				<a href="#" class="social google"><i class="fab fa-google-plus-g"></i></a>
				<a href="#" class="social linkdin"><i class="fab fa-linkedin-in"></i></a>
			</div>
			
			<div class="login-form">
				<div class="form-title-wrapper">
					<div class="form-title">OR</div>
				</div>
            
                <div class="errors">
                    <p class="error big-error" *ngIf="errors.email">
                        <mat-icon svgIcon="warning"></mat-icon>
                        <span>{{errors.email}}</span>
                    </p>
                    <p class="error big-error" *ngIf="errors.password">
                        <mat-icon svgIcon="warning"></mat-icon>
                        <span>{{errors.password}}</span>
                    </p>


                </div>
			
                    <input type="email" placeholder="Email" formControlName="email" id="login-email" required/>
                    

                    <input type="password" placeholder="Password" formControlName="password" id="login-password" required/>
                    

                    <input type="checkbox" class="remember-me" formControlName="remember"/>
                    <span class="inline-span">Stay signed in for a month</span>



                    <a [routerLink]="['/forgot-password']">Forgot your password?</a>
                    <button type="submit" [disabled]="loading$ | async" trans>Sign In</button>

			
			</div>
			
			
		</form>
	</div>
	
	
	<div class="overlay-container">
		<div class="overlay">
			<div class="overlay-panel overlay-left">
				<h1>One Of Us?</h1>
				<p>If you already have an account, just sign in. We've missed you!</p>
				<button class="ghost" id="signIn" (click)="onClick('in')">Sign In</button>
			</div>
			<div class="overlay-panel overlay-right">
				<h1>New Here?</h1>
				<p>Sign up and let us make you wave with music</p>
				<button class="ghost" id="signUp" (click)="onClick('up')">Sign Up</button>
			</div>
		</div>
	</div>
	
	
	
	<div class="figures-container">
		<div class="figures-wrapper">
			<div class="first"></div>
			<div class="second"></div>
			<div class="third"></div>
			<div class="forth"></div>
			<div class="fifth"></div>
			<div class="sixth"></div>
			<div class="seven"></div>
			
		</div>
	</div>
	
	<script>
        const signUpButton = document.getElementById('signUp');
        const signInButton = document.getElementById('signIn');
        const container = document.getElementById('container');
    
        signUpButton.addEventListener('click', () => {
            container.classList.add("right-panel-active");
        });
    
        signInButton.addEventListener('click', () => {
            container.classList.remove("right-panel-active");
        });
    </script>


	
</div>

