import {Injectable} from '@angular/core';
import {Track} from '../../models/Track';
import {Tracks} from '../tracks/tracks.service';
import {CookieService} from '@common/core/services/cookie.service';
import {AppCurrentUser} from '../../app-current-user';

@Injectable({
    providedIn: 'root'
})
export class TrackPlays {
    private loggedTracks: number[] = [];
    constructor(private tracks: Tracks,
        private cookie: CookieService,
        public currentUser: AppCurrentUser,
    ) {}

    public increment(track: Track, queueId: string) {
        if ( ! track || this.hasBeenPlayed(track)) return;
        this.loggedTracks.push(track.id);



        var cookieId = "";

        if (this.currentUser.isLoggedIn() == false) {
            //If Cookie not present create it
            if (this.cookie.get('recIdentifier') == null) {
                //Generate Unique Identifier
                const dateString = Date.now().toString(36);
                const randomness = Math.random().toString(36).substr(2);
                var uniqueIdentifier =  dateString + randomness;

                //Set Cookie
                this.cookie.set('recIdentifier', uniqueIdentifier, 365)
            }

            cookieId = this.cookie.get('recIdentifier').toString();
        }




        this.tracks.logPlay(track, {queueId, cookieId}).subscribe(() => {}, () => {});
    }

    /**
     * Clear last track, if it matches specified track.
     * This will allow this track plays to be incremented again.
     */
    public clearPlayedTrack(track: Track) {
        if ( ! track) return;
        this.loggedTracks.splice(this.loggedTracks.indexOf(track.id), 1);
    }

    /**
     * Check if current user has already incremented plays of specified track.
     */
    private hasBeenPlayed(track: Track): boolean {
        return this.loggedTracks.indexOf(track.id) > -1;
    }
}
